import { WSButton, WSText } from "@wingspanhq/fe-component-library";
import { IPayoutSettingsResponse } from "@wingspanhq/payments/dist/interfaces";
import React, { useMemo, useState } from "react";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { FormInputOnboardingPayoutMethod } from "../../../../shared/components/FormInputOnboardingPayoutMethod";
import { usePaymentCardForm } from "../../../../shared/hooks/usePaymentCardForm";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { WS_LINKS } from "../../../../types/wsLinks";
import { selectorCurrentPayoutMethod } from "./selectorCurrentPayoutMethod";
import { IInternalAccount } from "../../../../services/api/banking/types";

type Props = {
  payoutSettings: IPayoutSettingsResponse;
  internalAccount?: IInternalAccount;
  payoutMethods: PayoutMethodType[];
  onSubmit: (payoutMethod?: PayoutMethodType, paymentCardId?: string) => void;
  error?: any;
  isLoading?: boolean;
};

export const Form: React.FC<Props> = ({
  payoutSettings,
  internalAccount,
  payoutMethods,
  onSubmit,
  error,
  isLoading
}) => {
  const initialPayoutMethod = useMemo(
    () => selectorCurrentPayoutMethod(payoutSettings, internalAccount),
    [payoutSettings, internalAccount]
  );

  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState<
    PayoutMethodType | undefined
  >(initialPayoutMethod);

  const otherPayoutMethods = payoutMethods.filter(
    p => p !== initialPayoutMethod
  );
  const queryFeatureFlags = useFeatureFlags();
  const paymentCardForm = usePaymentCardForm();

  return (
    <>
      <WSText.Heading4 data-testid="heading" mb="XL">
        {initialPayoutMethod
          ? "Your current payment option:"
          : "Where should we deposit your income?"}
      </WSText.Heading4>

      {initialPayoutMethod && (
        <FormInputOnboardingPayoutMethod
          type={initialPayoutMethod}
          checked={selectedPayoutMethod === initialPayoutMethod}
          onChange={setSelectedPayoutMethod}
        />
      )}

      <WSText my="XL">
        If you would like to change your payout method, please choose your
        preferred payment, agree to the Terms of Service and click UPDATE below:
      </WSText>

      {otherPayoutMethods.map(payoutMethod => (
        <FormInputOnboardingPayoutMethod
          type={payoutMethod}
          checked={selectedPayoutMethod === payoutMethod}
          onChange={setSelectedPayoutMethod}
        />
      ))}

      <WSErrorMessage contextKey="PayoutSettings" error={error} mb="XL" />

      <WSButton
        mb="XL"
        name="continue"
        onClick={() => {
          if (
            queryFeatureFlags.data?.paymentCardAsPayoutMethod &&
            selectedPayoutMethod === PayoutMethodType.Instant
          ) {
            paymentCardForm.open({
              onSuccess: paymentCard => {
                onSubmit(selectedPayoutMethod, paymentCard.paymentCardId);
              }
            });
          } else {
            onSubmit(selectedPayoutMethod);
          }
        }}
        loading={isLoading}
        disabled={!selectedPayoutMethod}
        fullWidth
      >
        {initialPayoutMethod ? "Update" : "Continue"}
      </WSButton>

      <WSText.ParagraphXs color="gray600" mb="M">
        We work with Stripe to power credit payments. By continuing, you agree
        to{" "}
        <a
          href={WS_LINKS.stripeConnectedTerms}
          target="_blank"
          rel="noreferrer"
        >
          Stripe Connected Account Agreement
        </a>
        .
      </WSText.ParagraphXs>

      <WSText.ParagraphXs color="gray600">
        Wingspan is a financial services technology company and is not a bank.
        Banking services provided by Lead Bank, Member FDIC.
      </WSText.ParagraphXs>
    </>
  );
};
