import {
  IPayoutSettingsResponse,
  PayoutDestinationType,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import { PayoutMethodType } from "../../../../shared/types/payments";
import { IInternalAccount } from "../../../../services/api/banking/types";

export const selectorCurrentPayoutMethod = (
  payoutSettings: IPayoutSettingsResponse,
  internalAccount?: IInternalAccount
) => {
  const standardDestination = payoutSettings.payoutDestinations.find(
    d => d.payoutMethod === PayoutPreferences.Standard
  );

  const existingPayoutMethod: PayoutMethodType | undefined =
    payoutSettings.payoutPreferences === PayoutPreferences.Instant
      ? PayoutMethodType.Instant
      : standardDestination?.destinationType === PayoutDestinationType.Account
      ? PayoutMethodType.ACH
      : standardDestination?.destinationType === PayoutDestinationType.WeGift
      ? PayoutMethodType.GiftCard
      : internalAccount
      ? PayoutMethodType.Wallet
      : undefined;

  return existingPayoutMethod;
};
